@import url(https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200;400;600;700&display=swap);
body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Be Vietnam Pro', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
}

/* .makeStyles-paper-11, */
/* .makeStyles-paper-11 h2, */
/* .MuiTypography-body1,
.MuiPaper-root, */

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-color: #f7f7d5;
}

.background {
  position: absolute;
  z-index: -9000;
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/gbground.045f4e73.svg);
  -webkit-filter: blur(180px);
          filter: blur(180px);
  background-size: 150% 150%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Be Vietnam Pro', sans-serif !important;
  text-transform: uppercase;
}

.MuiToolbar-regular {
  color: #e0e3bd;
}
.MuiStepLabel-label.MuiStepLabel-active {
  font-weight: 700;
}

code {
  font-family: 'Be Vietnam Pro', monospace;
}

.wallet-button {
  color: white;
  font-family: 'Be Vietnam Pro', sans-serif !important;
  font-size: 1.2rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
.wallet-button:hover {
  color: #58d6a6 !important;
}

.shinyButton {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: none !important;
  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  background-color: #bcff99 !important;
  color: black !important;
}

.shinyButtonDisabled {
  border-radius: 15px;
  border: 1px solid grey !important;
  text-transform: none !important;
  color: grey !important;
}

.shinyButton:hover {
  background-color: #58d6a6 !important;
  color: black !important;
}

.shinyButtonSecondary {
  text-transform: none !important;
  border-radius: 15px;
  font-size: 16px !important;
  line-height: 24px !important;
  background-color: #bcff99 !important;
  padding: 12px 16px !important;
  color: #012316 !important;
  /* box-shadow: 0px 0px 5px 0px rgba(251,221,76,1); */
}

.shinyButtonDisabledSecondary {
  border-radius: 15px;
  border: 1px solid grey !important;
  color: grey !important;
}

.shinyButtonSecondary:hover {
  background-color: #58d6a6 !important;
  color: #012316 !important;
}

.navTokenPrice {
  padding-top: 5px;
  padding-right: 15px;
  color: black;
}

.navTokenIcon {
  height: 30px !important;
  width: 30px !important;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navTokenIcon.quartz {
  background-image: url(/static/media/ames.b23a21e8.svg);
}
.navTokenIcon.qshare {
  background-image: url(/static/media/ashare.61906a82.svg);
}
.navTokenIcon.UST {
  background-image: url('https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766');
  background-size: 24px 24px;
}

